.carouselSO {
  background-color: rgba(11, 16, 17, 0.6);
  color: rgb(255, 255, 255);
  border-radius: 12px;
  padding-top: 0%;
  padding-bottom: 2%;
  padding-left: 3px;
  padding-right: 3px;
  //   height: 20%;
  //   top: 75%;
  z-index: 1;
}

.cardForm {
  background-color: #ffffff;
  // box-sizing: border-box;
  border-radius: 8px;
  // border-color: rgb(102, 14, 14);
  border: 1px solid rgba(226, 226, 226, 0.5);
  color: rgb(0, 0, 0);
  max-width: 100%;
  margin: 20px 0 70px;
  // min-height: 400px;
  padding: 30px;
  // box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.15);

  &.cardForm:hover {
    box-shadow: 0px 6px 10px -4px rgba(0, 0, 0, 0.15);
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    -moz-transform: none;
  }
}

.cardArticleForm {
  background-color: #eeeeee;
  // box-sizing: border-box;
  border-radius: 8px;
  // border-color: rgb(102, 14, 14);
  // border-width: 10px;
  color: rgb(0, 0, 0);
  max-width: 100%;
  // margin: 20px 0 70px;
  // min-height: 400px;
  padding: 10px;

  &.cardArticleForm:hover {
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.39);
    transform: none;
    -webkit-transform: none;
    -ms-transform: none;
    -moz-transform: none;
  }
}

.btn-fab {
  position: fixed;
  right: 20px;
  bottom: 20px;
  z-index: 999;
}

[contenteditable] {
  outline: 0px solid transparent;
}
