.ce-block__content,
.ce-toolbar__content {
  max-width: 1140px;
}

//img-rounded img-responsive img-shadow
.image-tool__image-picture {
  max-width: 450px;
  max-height: 350px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  box-shadow: 0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12), 0 5px 5px -3px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.cdx-input {
  border: none;
  -webkit-box-shadow: none;
  text-align: center;
}

.card img {
  border-radius: 15px;
}
